$base-spacing: 1rem;
$border-radius: 0.25rem;
$transition-duration: 0.25s;
$box-shadow-sm: 0px 2px 8px rgba(0, 0, 0, 0.05);
$box-shadow-std: 0px 2px 8px rgba(0, 0, 0, 0.15);
$box-shadow-raised: 0px 4px 12px rgba(0, 0, 0, 0.15);
$sm-width: 300px;
$md-width: 450px;
$lg-width: 600px;

$primary-color: rgb(2, 91, 192);
$red: rgb(255, 0, 0);
$green: rgb(0, 128, 0);
$green-med: rgb(0, 110, 0);
$green-dark: rgb(1, 61, 1);
$grey: rgb(169, 169, 169);