@import "../../style-global/variables.scss";
@import "../../style-global/mixins.scss";

.toDoItem {
    @include hover-item;
    align-items: center;
    border-radius: $border-radius;
    display: flex;
    justify-content: space-between;
    margin: $base-spacing/2 0;
    padding: $base-spacing/4;
    width: 100%;

    p {
        display: inline-block;
        flex-grow: 1;
        margin: 0;
        max-width: 80%;
        padding: 0;

        @media screen and (min-width: $lg-width) {
            max-width: 84%;
        }
    }

    input, button {
        border-radius: $border-radius;
        cursor: pointer;
        height: 20px;
        width: 20px;
    }

    input[type=checkbox] {
        margin: 0 $base-spacing/2 0 0;

        &:focus-visible { 
            border-radius: $border-radius;
            border: 1px solid $grey;
        }
    }

    input[type=checkbox]:checked {
        accent-color: $green;
        color: #fff;

        &:hover {
            filter: brightness(1.5);
        }
      }

    button {
        background-color: $grey;
        border: none;
        margin-left: $base-spacing/2;
        padding: 0;
        position: relative;

        &:hover, &:focus {
            background-color: $red;
        }

        &:before, &:after {
            background-color:  white;
            content: ' ';
            height: 17px;
            left: 43%;
            position: absolute;
            top: 10%;
            width: 3px;
        }

        &:after {
            transform: rotate(-45deg);
        }

        &:before {
            transform: rotate(45deg);
        }
    }
}

.completed {
    color: $red;
    text-decoration: line-through;
}