@import "./style-global/variables.scss";
@import "./style-global/mixins.scss";

.header {
  align-items: center;
  box-shadow: $box-shadow-sm;
  display: flex;
  flex-direction: column;
  justify-content: center;

  h1 {
    background-color: $primary-color;
    box-shadow: $box-shadow-raised;
    color: white;
    margin: 0;
    padding: $base-spacing;
    text-align: center;
    width: 100vw;
  }

  a {
    @include hover-item;
    border-radius: $border-radius;
    box-shadow: $box-shadow-std;
    color: black;
    display:inline-block;
    font-weight: 600;
    margin: $base-spacing;
    padding: $base-spacing/2 $base-spacing;
    text-align: center;
    text-decoration: none;
    text-transform: capitalize;
    width: 110px;

    &:focus { 
      outline: 1px solid $grey;
    }
  }
}
